import { QueryResult, useQuery } from '@apollo/client';

import { getRrn } from '../queries';

import { GetRrnQuery, GetRrnQueryVariables } from '@typings/operations';

type GetRrnResult = Pick<
  QueryResult<GetRrnQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useGetRRN = (netForumMemberId: string): GetRrnResult =>
  useQuery<GetRrnQuery, GetRrnQueryVariables>(getRrn, {
    variables: { netForumMemberId },
  });
