import { QueryResult, useQuery } from '@apollo/client';

import { getFiscalCode } from '../queries';

import {
  GetFiscalCodeQuery,
  GetFiscalCodeQueryVariables,
} from '@typings/operations';

type GetFiscalCodeResult = Pick<
  QueryResult<GetFiscalCodeQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useGetFiscalCode = (
  netForumMemberId: string
): GetFiscalCodeResult =>
  useQuery<GetFiscalCodeQuery, GetFiscalCodeQueryVariables>(getFiscalCode, {
    variables: { netForumMemberId },
  });
