import { QueryResult, useQuery } from '@apollo/client';

import { getPan } from '../queries';

import { GetPanQuery, GetPanQueryVariables } from '@typings/operations';

type GetPanResult = Pick<
  QueryResult<GetPanQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useGetPan = (netForumMemberId: string): GetPanResult =>
  useQuery<GetPanQuery, GetPanQueryVariables>(getPan, {
    variables: { netForumMemberId },
  });
